<template>
  <div class="container-fluid">
    <b-row>
      <b-col
          class="niche-field-name-class"
          style="font-size: 15px"
      >
         {{$t('payment_mode')}}
      </b-col>
    </b-row>
    <b-row class="mt-2" no-gutters>
      <b-col cols="12" align="left">
          <d-radio-button
              @on:radio-button-check="activate = false; isActive = true; isCoin = true; type = 'administration';"
              :text="'Argent'"
              :row="{singleSelection: true}"
          />
      </b-col>
      <b-col cols="12" align="left" v-if="wallet">
          <d-radio-button
              @on:radio-button-check="activate = false; isActive = true; isCoin = true; type = 'wallet';"
              :text="'Wallet - ' + parseFloat('' + (this.wallet.balance / 100)).toFixed(2) + ' ' + $store.getters['currency/getCurrency'][currentCurrency]"
              :row="{singleSelection: true}"
          />
      </b-col>
      <b-col v-if="spinner.clubPaymentTokens">
        <b-spinner
            variant="blue-light-spinner"
            type="grow"
        ></b-spinner>
      </b-col>
      <b-col v-if="clubPaymentTokens.length > 0" cols="4" align="left">
        <d-radio-button
            @on:radio-button-check="activate = true; isActive = true; isCoin = false; type = 'payment_token';"
            text="Crédit"
            :row="{singleSelection: true}"
        />
      </b-col>
      <b-col v-if="activate && clubPaymentTokens.length > 0" align="center" cols="6">
        depuis
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="activate && clubPaymentTokens.length > 0">
      <b-col align="center" cols="12">
        <select class="form-control background-light-blue-inputs">
          <template v-for="(paymentToken, i) of clubPaymentTokens">
            <option :key="'paymentTOkenPrice'+ i">
              {{ paymentToken.name }}
            </option>
          </template>
        </select>
      </b-col>
    </b-row>
    <div class="border-bottom-grey"></div>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-input-group>
          <b-form-input
              min="0.01"
              step="0.01"
              class="background-light-blue-inputs"
              type="number"
              :disabled="!isActive"
              v-model="toPay"
              :placeholder="$t('general.actions.numbers.zero')"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <div v-if="isCoin">{{ $store.getters["currency/getCurrency"][currentCurrency] }}</div>
            <div v-else>
              <img :height="10" src="../../../assets/icons/sidebar/default/payment.svg" alt/>
            </div>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col align="center">
        <button
            :disabled="!isActive"
            type="submit"
            class="btn d-btn-primary btn-sm validate-btn-padding"
            v-on:click="addPayment"
        >
          {{ $t('general.actions.validate') }}
        </button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import {getClientFromUserClient} from "@api/doinsport/services/client/client.api";
  import {getPaymentToken} from "@api/doinsport/services/client/payment-token/payment-token.api";
  import Item from "@/classes/doinsport/Item";
  import Cart from "@/classes/doinsport/Cart";
  import Payment from "@/classes/doinsport/Payment";
  import {addCart, addPayment} from "@api/doinsport/services/payments/payments.api";
  import {getClientWallet} from "@api/doinsport/services/client/wallet/wallet.api";
  import Button from "@/components/doinsport/Button";

  export default {
    components: {Button},
    props: {
      paymentData: {
        type: Object,
        default: this,
      },
      booking: {
        type: Object,
        default: {},
      },
    },
    data: () => ({
      type: '',
      activate: false,
      isActive: false,
      isCoin: true,
      toPay: null,
      wallet: null,
      spinner: {
        clubPaymentTokens: false,
      },
      idClub: null,
      clubPaymentTokens: [],
    }),
    computed: {
      currentCurrency () {
        return JSON.parse(localStorage.getItem('current-club')).currency;
      },
    },
    methods: {
      addPayment() {
        const item = new Item({
          quantity: 1,
          product: this.booking['@id'],
          price: this.toPay,
        });
        const cart = new Cart({
          items: [item]
        });
        const payment = new Payment({
          userClient: this.paymentData.participant.user?(this.paymentData.participant.user['@id']?this.paymentData.participant.user['@id']:this.paymentData.participant.user):null,
          client: this.paymentData.participant.client?(this.paymentData.participant.client['@id']?this.paymentData.participant.client['@id']:this.paymentData.participant.client):null,
          amount: parseInt((this.toPay * 100) + '', null),
          provider: this.type,
          method: 'card',
          club: '/clubs/' + this.$store.getters["auth/currentClub"].id,
          metadata: {
            addedBy: this.$store.getters["auth/currentUser"]?this.$store.getters["auth/currentUser"].firstName:'admin',
          },
          currency: this.paymentData.currency,
          cart: cart,
        });

        addCart(cart).then(resp => {
          if (resp.data) {
            payment.cart = resp.data['@id'];
            addPayment(payment).then(resp => {
              if (resp.data) {
                if (resp.data.status === "succeeded") {
                  this.$emit('on:participant_pay_form_reload_booking');
                }
              }
            });
          }
        });
      }
    },
    created () {
      this.type = null;
      this.idClub = this.$store.getters["auth/currentClub"].id;

      this.toPay = parseFloat(this.paymentData.toPay);

      if (this.paymentData.participant.client === null && this.paymentData.participant.user !== null) {
        getClientFromUserClient(this.paymentData.participant.user.id,  this.idClub)
            .then ((response) => {
              if (response.data['hydra:member'].length > 0) {
                this.spinner.clubPaymentTokens = true;
                const clientId = response.data['hydra:member'][0].id;
                getPaymentToken(clientId, this.$moment.utc().format('YYYY-MM-DD'), 0)
                    .then((response) => {
                      response.data['hydra:member'].forEach(clubPaymentToken => {
                        this.clubPaymentTokens.push(clubPaymentToken.paymentToken);
                      });
                    })
                    .finally(() => {
                      // filter clubPaymentTokens with blockprice paymentToken waiting for paymentTokenPrices in blockPrices
                      this.spinner.clubPaymentTokens = false;
                    })
                ;
              }
            })
        ;
      }

      getClientWallet(this.paymentData.participant.client.id).then(resp => {
        if (resp.data) {
          resp.data['hydra:member'].forEach(item => {
            this.wallet = item;
          });
        }
      });
    }
  }
</script>
<style scoped>
/deep/ .con-vs-radio {
  -webkit-box-align: center;
  -ms-flex-align: start;
  align-items: start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
   justify-content: start;
}

/deep/ .table.dataTable input, table.dataTable select {
  border: 1px solid gainsboro;
  height: 30px;
}


/deep/ .input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  border: 1px solid gainsboro;
}
</style>
