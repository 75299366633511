import { hydrate } from "@/utils/form";

export default class Item {
    quantity = null;
    product = null;
    price = null;

    constructor(object = null) {
        if (object) {
            this.serialize(object);
        }
    }

    serialize (object) {
        hydrate(this, object);
    }
}
